*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.stylepicker {
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%)translateY(-28px);
}

@media only screen and (width <= 900px) {
  .stylepicker {
    transform: translateY(-100%)translateY(-25px);
  }
}

.stylepicker--visible {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .5s;
}

.stylepicker p {
  color: #413e3e;
  letter-spacing: -.03em;
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (width <= 900px) {
  .stylepicker p {
    font-size: 14px;
  }
}

.stylepicker-list {
  -weblit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0003;
  border-radius: 39px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
  display: inline-flex;
}

@media only screen and (width <= 900px) {
  .stylepicker-list {
    margin-top: 14px;
  }
}

.stylepicker-button {
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  display: flex;
  position: relative;
}

@media only screen and (width <= 900px) {
  .stylepicker-button {
    width: 44px;
    height: 44px;
  }
}

.stylepicker-button--active .stylepicker-button__check {
  display: block;
}

.stylepicker-button--active:before {
  content: "";
  z-index: 2;
  background-color: #fff6;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 900px) {
  .stylepicker-button--active:before {
    width: 35px;
    height: 35px;
  }
}

.stylepicker-button__icon {
  object-fit: cover;
  object-position: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
}

.stylepicker-button__check {
  z-index: 3;
  width: 28px;
  height: 28px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 900px) {
  .stylepicker-button__check {
    width: 18px;
    height: 18px;
  }
}

/*# sourceMappingURL=index.c37b3e2f.css.map */
