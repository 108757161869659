*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%

$mobile: "only screen and (max-width: 900px)"

.stylepicker
  position: absolute
  left: 0
  top: 0
  transform: translateY(-100%) translateY(-28px)
  z-index: 2
  pointer-events: none
  opacity: 0
  @media #{$mobile}
    transform: translateY(-100%) translateY(-25px)
  &--visible
    transition: opacity .5s
    pointer-events: auto
    opacity: 1
  p
    font-size: 20px
    font-weight: 700
    color: #413E3E
    text-decoration: underline
    letter-spacing: -0.03em
    @media #{$mobile}
      font-size: 14px
  &-list
    display: inline-flex
    align-items: center
    justify-content: flex-start
    border-radius: 39px
    background-color: rgba(0,0,0,.2)
    margin-top: 17px
    -weblit-backdrop-filter: blur(5px)
    -moz-backdrop-filter: blur(5px)
    backdrop-filter: blur(5px)
    @media #{$mobile}
      margin-top: 14px
  &-button
    position: relative
    display: flex
    align-items: center
    justify-content: center
    background: transparent
    border: none
    width: 70px
    height: 70px
    z-index: 1
    cursor: pointer
    @media #{$mobile}
      width: 44px
      height: 44px
    &--active
      .stylepicker-button__check
        display: block
      &::before
        content: ''
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        width: 55px
        height: 55px
        border-radius: 50%
        background-color: rgba(255, 255, 255, 0.4)
        z-index: 2
        @media #{$mobile}
          width: 35px
          height: 35px
    &__icon
      display: block
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      z-index: 1
    &__check
      display: none
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      width: 28px
      height: 28px
      z-index: 3
      @media #{$mobile}
        width: 18px
        height: 18px
